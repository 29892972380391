<template>
  <div class="counter">
   
    <div class="style1">
    <div class="digitX" >{{countText.slice(0,1)}}</div>
    <div class="digitX">{{countText.slice(1,2)}}</div>
    <div class="digitX" >{{countText.slice(2,3)}}</div>
    <div class="digitX" >{{countText.slice(3,4)}}</div>
    <div class="digitX" >{{countText.slice(4,5)}}</div>
    <div class="digitX" style="opacity:0;">.</div>
    <div class="digitX colm" >{{countText.slice(5,6)}}</div>
    <div class="digitX colm">{{countText.slice(6,7)}}</div>
    <div class="digitX colm" >{{countText.slice(7,8)}}</div>
    </div>

     <div class="style1a">
    <div class="digitX" >{{countText.slice(0,1)}}</div>
    <div class="digitX">{{countText.slice(1,2)}}</div>
    <div class="digitX" >{{countText.slice(2,3)}}</div>
    <div class="digitX" >{{countText.slice(3,4)}}</div>
    <div class="digitX" >{{countText.slice(4,5)}}</div>
     <div class="digitX" style="opacity:0;width:5vw;">.</div>
    <div class="digitX colm" >{{countText.slice(5,6)}}</div>
    <div class="digitX colm">{{countText.slice(6,7)}}</div>
    <div class="digitX colm" >{{countText.slice(7,8)}}</div>
    </div>

         <div class="style1a">
    <div class="digitX" >{{countText.slice(0,1)}}</div>
    <div class="digitX">{{countText.slice(1,2)}}</div>
    <div class="digitX" >{{countText.slice(2,3)}}</div>
    <div class="digitX" >{{countText.slice(3,4)}}</div>
    <div class="digitX" >{{countText.slice(4,5)}}</div>
     <div class="digitX" style="width:10vw;">.</div>
    <div class="digitX colm" >{{countText.slice(5,6)}}</div>
    <div class="digitX colm">{{countText.slice(6,7)}}</div>
    <div class="digitX colm" >{{countText.slice(7,8)}}</div>
    </div>
    <div class="style2">
    <div class="digitX" >{{countText.slice(0,1)}}</div>
    <div class="digitX">{{countText.slice(1,2)}}</div>
    <div class="digitX" >{{countText.slice(2,3)}}</div>
    <div class="digitX" >{{countText.slice(3,4)}}</div>
    <div class="digitX" >{{countText.slice(4,5)}}</div>
    <div class="digitX" style="opacity:0;">.</div>
    <div class="digitX colm" >{{countText.slice(5,6)}}</div>
    <div class="digitX colm">{{countText.slice(6,7)}}</div>
    <div class="digitX colm" >{{countText.slice(7,8)}}</div>
    </div>

    <div class="style3">
    <div class="digitX" >{{countText.slice(0,1)}}</div>
    <div class="digitX">{{countText.slice(1,2)}}</div>
    <div class="digitX" >{{countText.slice(2,3)}}</div>
    <div class="digitX" >{{countText.slice(3,4)}}</div>
    <div class="digitX" >{{countText.slice(4,5)}}</div>
    <div class="digitX" style="opacity:0;">.</div>
    <div class="digitX colm" >{{countText.slice(5,6)}}</div>
    <div class="digitX colm">{{countText.slice(6,7)}}</div>
    <div class="digitX colm" >{{countText.slice(7,8)}}</div>
    </div>
  
    <h1>{{count}} meters</h1>
  </div>
</template>
<script>
export default {
  name: 'Home',
  components: {
  
  },
  data() {
    return {
      count:0,
      countText:"0",
      startTime:null

    }
  },
  mounted(){
    this.startTime=new Date()
    window.requestAnimationFrame(this.tick);
  },
  methods:{
    tick(){
      this.count=Math.floor(((new Date()-this.startTime)/1000)*1670*1000/60/60)
      if(this.count>40075000){
        this.count=0
      }
      //this.count=0
      this.countText=("00000000"+this.count).slice(-8)
      window.requestAnimationFrame(this.tick);
      
    }
  }

}
</script>
<style scoped>
@font-face {
  font-family: "Repetition Scrolling";
  src: local("Repetition  Scrolling"),
   url(../assets/fonts/repet.ttf) format("truetype");
}
.digitX{
 
  text-align: center;
  
  color:#ffbc32;
  opacity: 0.8;
  
}

.style1{
 display:flex;
 margin:auto;
 font-family:"Repetition Scrolling";
 font-size:20vw;
 margin-top:20vh;
 margin-bottom:20vh;
 
}
.style1a{
 display:flex;
 margin:auto;
 font-family:"Repetition Scrolling";
 font-size:21vw;
 margin-top:20vh;
 margin-bottom:20vh;
 
}
.style2{
 display:flex;
 margin:auto;
 font-family:"verdana";
 font-size:16vw;
 margin-top:20vh;
 margin-bottom:20vh;
 font-weight: 600;
}
.style3{
 display:flex;
 margin:auto;
 font-family:"verdana";
 font-size:18vw;
 margin-top:20vh;
 margin-bottom:20vh;
}
.watchBand{
  position:relative;
  width:500px;
  height:500px;
  background-image:url(../assets/images/Band2.png);
  background-size:cover;
  margin: auto;
}
.colm{
 /* color:#faeda4;*/
    color:#ffbc32;
}
</style>